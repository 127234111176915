<template>
  <section class="col-7">
    <modal-loading :is-loading="loading" :message="loadingMessage" />
    <modal-confirmation
      :state-modal="isModal"
      title="削除してもよろしいですか？"
      color="red"
      label="削除"
      @hideModal="toggleModal"
      @apply="deleteItem"
    />
    <h2 v-if="$route.meta === 'new'" class="mb-10">ユーザー登録</h2>
    <h2 v-if="$route.meta === 'edit'" class="mb-10">ユーザーの編集</h2>

    <section>
      <div>
        <div v-if="isMe">
          <v-alert text color="warning" class="mb-8">
            ログインユーザー自身です。注意して編集を行ってください。
          </v-alert>
        </div>

        <div>
          <v-text-field
            v-model="user.name"
            label="名前"
            type="text"
            :class="errorMessages.name ? 'mb-4' : ''"
            :error-messages="errorMessages.name"
            required
            outlined
          />
        </div>

        <div>
          <v-text-field
            v-model="user.email"
            label="メールアドレス"
            type="text"
            :class="errorMessages.email ? 'mb-4' : ''"
            :error-messages="errorMessages.email"
            required
            outlined
          />
        </div>

        <div>
          <v-select
            v-model="user.role_id"
            label="権限ステータス"
            :items="roles"
            item-text="label"
            item-value="value"
            :class="errorMessages.role_id ? 'mb-4' : ''"
            :error-messages="errorMessages.role_id"
            outlined
          />
        </div>

        <div class="mb-5">
          <div class="subtitle-1 mb-2">パスワード</div>
          <password
            v-model="user.password"
            default-class="Password__input"
            :secure-length="9"
            :required="false"
            :toggle="true"
            @feedback="passwordFeedback"
          />
          <v-alert v-if="showPasswordStrengthMessages" text color="error">
            <div
              v-for="(message, i) in passwordStrengthMessages"
              :key="i"
              class="py-1"
            >
              {{ message }}
            </div>
          </v-alert>
          <v-alert
            v-if="errorMessages.password"
            text
            color="error"
            class="mb-8"
          >
            {{ errorMessages.password }}
          </v-alert>
        </div>

        <div class="mb-10">
          <div class="subtitle-1 mb-2">パスワード確認</div>
          <password
            id="password_confirmation"
            v-model="user.password_confirmation"
            default-class="Password__input"
            :badge="false"
            :show-strength-meter="false"
            :required="false"
            :toggle="true"
          />
          <v-alert
            v-if="errorMessages.password_confirmation"
            text
            color="error"
            class="mb-8"
          >
            {{ errorMessages.password_confirmation }}
          </v-alert>
        </div>
      </div>

      <hr class="mt-8 mb-4" />

      <v-col>
        <v-row v-if="$route.meta === 'new'" justify="end">
          <v-btn color="primary" large @click="save"> 保存 </v-btn>
        </v-row>
        <v-row v-if="$route.meta === 'edit'" justify="space-between">
          <v-btn color="red" large outlined @click="toggleModal">
            削除する
          </v-btn>
          <v-btn color="primary" large @click="edit"> 上書き保存 </v-btn>
        </v-row>
      </v-col>
    </section>
  </section>
</template>

<script>
import Password from "vue-password-strength-meter";
import * as EmailValidator from "email-validator";

import ModalConfirmation from "../../components/ModalConfirmation.vue";
import ModalLoading from "../../components/ModalLoading.vue";
import { getData, createData, updateData, deleteData } from "../../axios";

export default {
  name: "UsersEdit",

  components: {
    Password,
    ModalConfirmation,
    ModalLoading,
  },

  data() {
    return {
      isModal: false,
      loading: false,
      loadingMessage: "",
      user: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: 2,
      },
      roles: [
        {
          id: 3,
          value: 3,
          label: "メンバー",
        },
        {
          id: 2,
          value: 2,
          label: "管理者",
        },
      ],
      errorMessages: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: "",
      },
      japaneseItems: {
        name: "名前",
        email: "メールアドレス",
        password: "パスワード",
        password_confirmation: "パスワード確認",
        role_id: "権限ステータス",
      },
      passwordFeedbackMessages: [],
      passwordStrengthMessages: [],
      showPasswordStrengthMessages: false,
    };
  },

  computed: {
    isValid() {
      const errors = Object.keys(this.errorMessages).filter((key) => {
        return this.errorMessages[key].length !== 0;
      });
      return errors.length === 0 && !this.showPasswordStrengthMessages;
    },
    isMe() {
      return (
        Number(this.$route.params.userId) === this.$store.getters["auth/me"].id
      );
    },
  },

  watch: {
    "user.role_id"() {
      this.user.role_id = Number(this.user.role_id);
    },
  },

  mounted() {
    if (this.$route.meta === "edit") {
      getData(`users/${this.$route.params.userId}`).then((res) => {
        this.user = res;
      });
    }
  },

  methods: {
    passwordFeedback({ suggestions, warning }) {
      this.passwordFeedbackMessages = [];
      if (suggestions.lenght !== 0) {
        suggestions.forEach((m) => {
          this.passwordFeedbackMessages.push(m);
        });
      }
      if (warning) {
        this.passwordFeedbackMessages.push(warning);
      }
    },
    toggleModal() {
      this.isModal = !this.isModal;
    },
    deleteItem() {
      this.loading = true;
      this.loadingMessage = "削除中...";
      deleteData(`users/${this.$route.params.userId}`)
        .then(() => {
          this.loading = false;
          this.loadingMessage = "";
          this.$store.dispatch("snackbar/setSnackbar", {
            message: `${this.user.name}さんのデータを削除しました。`,
            color: "success",
            timeout: 2000,
          });
          if (this.$route.params.from) {
            const from = Object.assign({}, this.$route.params.from);
            const params = Object.assign({}, from);
            // 空文字のクエリーを無くす：例）words=''
            Object.keys(params).forEach((key) => {
              if (params[key] === "") delete params[key];
            });
            this.$router.push({ name: "users", query: params });
          } else {
            this.$router.push({ name: "users" });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.loadingMessage = "";
          console.log(err);
        });
    },
    save() {
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "保存中...";
        getData("users/uniqueEmail", { email: this.user.email }).then((res) => {
          if (res.unique) {
            createData("users", this.user)
              .then(() => {
                this.loading = false;
                this.loadingMessage = "";
                this.$store.dispatch("snackbar/setSnackbar", {
                  message: `${this.user.name}さんを追加しました。`,
                  color: "success",
                  timeout: 2000,
                });
                this.$router.push("/users");
              })
              .catch((err) => {
                this.loading = false;
                this.loadingMessage = "";
                console.log(err.response);
              });
          } else {
            this.loading = false;
            this.loadingMessage = "";
            this.errorMessages["email"] = "メールアドレスが重複しています。";
          }
        });
      }
    },
    edit() {
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "更新中...";
        getData("users/uniqueEmail", {
          id: this.$route.params.userId,
          email: this.user.email,
        }).then((res) => {
          if (res.unique) {
            updateData(`users/${this.$route.params.userId}`, this.user)
              .then(() => {
                this.loading = false;
                this.loadingMessage = "";
                this.$store.dispatch("snackbar/setSnackbar", {
                  message: `${this.user.name}さんのデータを更新しました。`,
                  color: "success",
                  timeout: 2000,
                });
                if (this.$route.params.from) {
                  const from = Object.assign({}, this.$route.params.from);
                  const params = Object.assign({}, from);
                  // 空文字のクエリーを無くす：例）words=''
                  Object.keys(params).forEach((key) => {
                    if (params[key] === "") delete params[key];
                  });
                  this.$router.push({ name: "users", query: params });
                } else {
                  this.$router.push({ name: "users" });
                }
              })
              .catch((err) => {
                this.loading = false;
                this.loadingMessage = "";
                console.log(err.response);
              });
          } else {
            this.loading = false;
            this.loadingMessage = "";
            this.errorMessages["email"] = "メールアドレスが重複しています。";
          }
        });
      }
    },
    validate() {
      this.errorReset();
      if (!EmailValidator.validate(this.user.email)) {
        this.errorMessages.email = "メールアドレスが不正です。";
      }
      Object.keys(this.user).forEach((key) => {
        this.checkRequired(
          key,
          this.$route.meta === "new"
            ? ["name", "email", "password", "password_confirmation", "role_id"]
            : ["name", "email", "role_id"]
        );
        this.checkMaxLength(key, 32, [
          "name",
          "password",
          "password_confirmation",
        ]);
        this.checkMaxLength(key, 127, ["email"]);
        this.checkMinLength(key, 9, ["password", "password_confirmation"]);
      });
      this.checkConfirmation();
      if (
        this.passwordFeedbackMessages.length !== 0 &&
        this.user.password.length !== 0
      ) {
        this.showPasswordStrengthMessages = true;
        this.passwordStrengthMessages = this.passwordFeedbackMessages;
      }
    },
    checkRequired(key, requiredList) {
      if (requiredList.indexOf(key) !== -1 && this.user[key].length === 0) {
        this.errorMessages[key] = `${this.japaneseItems[key]}は必須項目です。`;
      }
    },
    checkMaxLength(key, max, targets) {
      targets.forEach((item) => {
        if (key === item && this.user[key] && max < this.user[key].length) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${max}文字以下で記入して下さい。`;
        }
      });
    },
    checkMinLength(key, min, targets) {
      targets.forEach((item) => {
        if (key === item && this.user[key] && min > this.user[key].length) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${min}文字以上で記入して下さい。`;
        }
      });
    },
    checkConfirmation() {
      if (this.user.password !== this.user.password_confirmation) {
        this.errorMessages["password_confirmation"] =
          "パスワードが一致しません。";
      }
    },
    errorReset() {
      this.showPasswordStrengthMessages = false;
      this.passwordStrengthMessages = [];
      Object.keys(this.errorMessages).forEach((key) => {
        this.errorMessages[key] = "";
      });
    },
  },
};
</script>
